import appendData from "common/utility";
import {
  del,
  get,
  getWithExportFile,
  post,
  postAsForm,
  put,
} from "helpers/api_helper";


export const addNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const addNewPlans = (data) => {
  return post("/plan/createPlan", data);
};

export const updateNewFaq = (data) => {
  return post("/admin/saveFaq", data);
};
export const updateNewPlan = (data) => {
  return post("/plan/createPlan", data);
};

export const getFaqDetail = () => {
  return get("/admin/getFaq");
};
export const getPlanDetail = () => {
  return get("/plan/getAllPlans");
};

export const deleteFaqData = (data) => {
  return del("/admin/deleteFaq", data);
};
export const deletePlanData = (data) => {
  return del("/plan/deletePlan", data);
};

export const adminLogin = (data) => {
  return post("/admin/login", data);
};

export const reSendOtp = (data) => {
  return post("/admin/resendotp", data);
};

export const forgetPassword = (data) => {
  return post("/admin/forgotPassword", data);
};

export const verifyOtp = (data) => {
  return post("/admin/verifyOtp", data);
};

export const resetPassword = (new_password, token) => {
  return post(
    "/admin/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

export const getUserProfile = () => {
  return get("/admin/me");
};

export const getAllUser = (
  pageNumber,
  pageSize,
  filter = "",
  fromDate = "",
  toDate = "",
  isBlocked = "",
  exportRequest = "false"
) => {
  return getWithExportFile(
    `/admin/allUser?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&isBlocked=${isBlocked}&exportRequest=${exportRequest}`
  );
};

export const getListingOfPairs = (
  pageNumber,
  pageSize,
  filter = "",
  fromDate = "",
  toDate = "",
  isBlocked = "",
  exportRequest = "false"
) => {
  return getWithExportFile(
    `/auth/pairsListing?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&isBlocked=${isBlocked}&exportRequest=${exportRequest}`
  );
};

export const getListingOfTokenPair = (
  pageNumber,
  pageSize,
  filter = "",
  fromDate = "",
  toDate = "",
  isStatus = "0",
  chainId = "ether",
  exportRequest = "false"
) => {
  return getWithExportFile(
    `/auth/tokenPairsListing?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&isStatus=${isStatus}&chain=${chainId == undefined ? "ether" : chainId.toString()}&exportRequest=${exportRequest}`
  );
};


export const refreshPairs = (type) => {
  return get("auth/refreshPairs");
};

export const refreshTokenPairs = (type) => {
  return get("auth/fetchNewPair");
};

export const getAllTransactions = (
  pageNumber,
  pageSize) => {
  return getWithExportFile(
    `/transaction/getAllTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const updateProfile = (data) => {
  return post("/admin/update", data);
};
export const updateUserDetail = (data) => {
  return post("/admin/updateUserDetail", data);
};
export const airdropTransactionSave = (data) => {
  return post("/transaction/saveTransaction", data);
};
export const updateProfileImage = (data) => {
  const formData = appendData(data);
  return postAsForm("/admin/uploadImage", formData);
};

export const changePassword = (data) => {
  return post("/admin/changePassword", data);
};

export const updateWithdrawStatus = (data) => {
  return put("/admin/updateWithdrawStatus", data);
};

export const csmUpdateFlow = (data) => {
  return post("/admin/csm/update", data);
};
export const getCsmFlow = (type) => {
  return get("/admin/csm/detail?type=" + type);
};
export const dashboardStats = (type) => {
  return get("admin/dashboardStats");
};
export const transactionGraph = (type) => {
  return get("admin/transactionGraph");
};
export const userStorageInfo = (userId) => {
  return get("admin/userStorage?userId=" + userId);
};
export const userTransactionsInfo = (userId) => {
  return get("admin/userTransactions?userId=" + userId);
};
export const getSocialInfo = (token) => {
  return get("auth/getSocialInfo?token=" + token);
};
export const getGeneralInfo = () => {
  return get("auth/getGeneralInfo");
};
export const generalSettingUpdate = (data) => {
  return post("/auth/generalSettingUpdate", data);
};
export const socialMediaSetting = (data) => {
  return post("/auth/socialMediaSetting", data);
};