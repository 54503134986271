import toastr from "toastr";
import "toastr/build/toastr.min.css";
import axios from 'axios';
import moment from 'moment'
import { apiRoute } from "routes/apiRoutes"
import { fireToast } from "common/toast";
import { ethers } from "ethers";

export const alerShow = (title, message, type = 'success') => {

    toastr.options = {
        // positionClass: positionClass,
        timeOut: 7000,
        // extendedTimeOut: extendedTimeOut,
        closeButton: true,
        // debug: debug,
        progressBar: true,
        // preventDuplicates: preventDuplicates,
        // newestOnTop: newestOnTop,
        // showEasing: showEasing,
        // hideEasing: hideEasing,
        // showMethod: showMethod,
        // hideMethod: hideMethod,
        // showDuration: 500000,
        // hideDuration: 1000000
    };

    if (type == 'success') {
        toastr.success(message, title);
    } else {
        toastr.error(message, title);
    }
}

export const toastCatchError = (error) => {

    toastr.options = {
        timeOut: 7000,
        closeButton: true,
        progressBar: true,
    };

    toastr.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data?.error ? error?.response?.data?.error : error?.message ? error?.message : "Something went wrong!", "Sorry");
}



// const apiHeader = async () => {
//     const obj = JSON.parse(localStorage.getItem("authUser"))
//     if (obj && obj.accessToken) {
//         axios.defaults.headers.common["Authorization"] = obj.accessToken;

//     } else {
//         return {}
//     }
// }

export const getUserDetail = () => {
    var userOBJ = JSON.parse(localStorage.getItem("authUser"))
    return userOBJ;
}

export const dateTimeformat = (date) => {
    return moment(date).format('DD MMM, YYYY - h:m A')
}

export const dateformat = (date) => {
    return moment(date).format('DD MMM, YYYY')
}

export const longStringConvertToShort = (str, length) => {
    if (str) {
        let startingLength = Math.ceil(length / 4)
        if (str.length > length) {
            const prefix = str.substring(0, startingLength); // Take first 10 characters
            const suffix = str.substring(str.length - startingLength); // Take last 10 characters
            return `${prefix}.....${suffix}`;
        }
    }
    return str;
};

export const handleClickCopy = (value) => {

    navigator.clipboard.writeText(value);
    fireToast("success", "Copied!")
};

export const listingOfMyStaking = async (list) => {
    try {

        let totalInvestment = 0;
        let totalMaturity = 0;
        let earningGraph = [0]
        let stakingArr = []
        let stakingHistoryArr = []
        let allStaking = []
        for (let index = 0; index < list.length; index++) {
            const element = list[index];

            for (let i = 0; i < element.stakes.length; i++) {
                const stake = element.stakes[i];

                var timestamp = Number(stake.startTime.toString()); // Unix timestamp in seconds
                var milliseconds = timestamp * 1000;
                var date = new Date(milliseconds);
                date.setSeconds(date.getSeconds() + Number(stake.duration.toString()));
                var updatedTimestamp = date.getTime() / 1000; // Convert back to seconds

                // console.log("New timestamp after adding 360 seconds:", updatedTimestamp);
                var currentTimestamp = Math.floor(Date.now() / 1000);
                var isUpdatedTimestampLessThanCurrentTime = updatedTimestamp < currentTimestamp;

                const parsedAmount = ethers.utils.formatEther(stake.amount)
                let interestApy = stake.interestRate ? parseFloat(Number(stake.interestRate.toString()) / 1e18) : 0;

                if (!stake.inActive) {

                    let maturityValue = parseFloat(parseFloat(parsedAmount) + (parseFloat(parsedAmount) * Number(interestApy) / 100));
                    let obj = {
                        planName: element.planName,
                        investmentAmount: parsedAmount,
                        duration: stake.duration.toString(),
                        apyInterest: interestApy,
                        stakedStatus: stake.staked,
                        startTime: stake.startTime.toString(),
                        // maturityDate: dateTimeformat(updatedTimestamp),
                        // startDate: dateStringToDate(stake.startTime.toString()),
                        maturityAmount: maturityValue,
                        maturityInterest: Number(Number(parsedAmount) * Number(interestApy) / 100),
                        maturityStatus: isUpdatedTimestampLessThanCurrentTime,
                        planIndexId: index,
                        stakingIndexId: i
                    }

                    if (stake.staked) {
                        stakingArr.push(obj)

                    } else {
                        stakingHistoryArr.push(obj)
                    }


                    totalInvestment = parseFloat(totalInvestment) + parseFloat(parsedAmount)
                    totalMaturity = parseFloat(totalMaturity) + parseFloat(maturityValue)

                    earningGraph.push(totalMaturity.toFixed(3))
                    allStaking.push(obj)
                }
            }
        }

        return { stakingArr, stakingHistoryArr, allStaking, totalInvestment, totalMaturity, earningGraph }

    } catch (error) {
        console.log("Create Instances Error --->>", error)
    }
}