import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
    Badge,
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap"
import "../../assets/scss/style.css"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { handleClickCopy, longStringConvertToShort } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { getListingOfTokenPair } from "services/userServices"
import HRNumbers from "human-readable-numbers"
import { dateFormat } from "common/utility"

const Dashboard = props => {

    document.title = "Token Pairs"
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    let [incermentInd, setIncrementInd] = useState(1);
    const perPage = 50;

    //fetch users
    const fetchData = (pagenumber, pagelimit, search = "", status = "progress") => {
        try {
            setLoaderStatus(true)

            getListingOfTokenPair(pagenumber, pagelimit, search, "", "", "progress").then(res => {
                console.log(res, "user listing >>>>>>")
                setData(res.data.items)

                let pageCount = Math.ceil(res.data.totalItems / perPage)
                setTotalCount(pageCount)
                setLoaderStatus(false)
            })
        } catch (err) {
            setLoaderStatus(false)
            fetchData(currentPage, perPage)
        }
    }

    useEffect(() => {
        fetchData(currentPage, perPage)
    }, [])

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName, statusFilter)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchClick = (e) => {

        const value = e.target.value;
        setSearchName(value);
        setCurrentPage(1)
        clearTimeout(typingTimeout);
        const timeoutId = setTimeout(() => {
            fetchData(1, perPage, e.target.value, statusFilter)
        }, 500);

        setTypingTimeout(timeoutId);

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div>
                                        <Row className="d-flex align-items-center">
                                            <Col lg="4">

                                                <h5 className=" text-color-gredient"> Under Progress </h5>
                                            </Col>
                                            <Col lg="8" className="d-flex align-items-center justify-content-end">

                                                <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                                    <div
                                                        className="position-relative"
                                                    >
                                                        <label htmlFor="search-bar-0" className="search-label mb-0">
                                                            <span id="search-bar-0-label" className="sr-only">
                                                                Search this table
                                                            </span>
                                                            <input
                                                                id="search-bar-0"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Search Name...`}
                                                                style={{ borderRadius: "4px" }}
                                                                onChange={handleSearchClick}
                                                            />
                                                        </label>
                                                        <i className="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>

                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>Token</Th>
                                                <Th>Liquidity</Th>
                                                <Th>Marketcap</Th>
                                                <Th>Holders</Th>
                                                <Th>Transactions</Th>
                                                <Th>Price</Th>
                                                <Th>Exchange Name</Th>
                                                <Th>Updated On</Th>
                                                <Th>Status</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                (loaderStatus) ?
                                                    <Tr>
                                                        <Td colSpan="8"> Loading ... </Td>
                                                    </Tr>
                                                    :
                                                    listingData.length > 0 ? (
                                                        listingData.map((element, index) => {
                                                            (currentPage > 1) ? incermentInd = ((currentPage - 1) * perPage) + 1 : 0;
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span className="co-name"> {incermentInd + (index)} </span>
                                                                    </td>
                                                                    <td>

                                                                        <h5 className="font-size-14 text-color-gredient">
                                                                            {element.token_name} ({element.token_symbol})
                                                                        </h5>
                                                                        <p className="my-1">
                                                                            <label className="mb-0">Token : </label>{" "}
                                                                            {longStringConvertToShort(element.token_address, 30)}
                                                                            <a href={`https://solscan.io/token/${element.token_address}`} target="_blank" rel="noreferrer">
                                                                                <i className="mdi mdi-open-in-new ms-2"></i>
                                                                            </a>
                                                                        </p>
                                                                        <p className="my-1">
                                                                            <label>Pair : </label>{" "}
                                                                            {longStringConvertToShort(element.pair_address, 30)}
                                                                            <a href={`https://www.dextools.io/app/en/ether/pair-explorer/${element.pair_address}`} target="_blank" rel="noreferrer">
                                                                                <i className="mdi mdi-open-in-new ms-2"></i>
                                                                            </a>
                                                                        </p>
                                                                    </td>
                                                                    <td> {element?.liquidity ? HRNumbers.toHumanString(element?.liquidity) : "-"} </td>
                                                                    <td> {element?.marketcap ? element?.marketcap : "-"} </td>
                                                                    <td> {element?.holder ? element?.holder : "-"} </td>
                                                                    <td> {element?.transactions ? element?.transactions : "-"} </td>
                                                                    <td> {element?.price ? element?.price : "-"} </td>

                                                                    <td>{element.exchange_name}</td>
                                                                    <td>
                                                                        {dateFormat(element.createdAt)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            element.status < 0 ? <Badge className="bg-danger"> Rejected </Badge> :
                                                                                element.status == 1 ? <Badge className="bg-primary"> Level 1 </Badge> :
                                                                                    element.status == 2 ? <Badge className="bg-primary"> Level 2 </Badge> :
                                                                                        element.status == 3 ? <Badge className="bg-primary"> Level 3 </Badge> :
                                                                                            element.status == 4 ? <Badge className="bg-success"> Level 4 </Badge> :
                                                                                                element.status == 5 ? <Badge className="bg-success"> Verified </Badge> :
                                                                                                    <Badge className="bg-warning">Pending</Badge>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8}>No Records Found</td>
                                                        </tr>
                                                    )}

                                        </Tbody>
                                    </Table>

                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
