import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import "../../assets/scss/style.css"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { handleClickCopy, longStringConvertToShort } from "admin/commonFunction"
import { dateFormat } from "common/utility"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { getListingOfTokenPair, getSocialInfo } from "services/userServices"
import discordImg from "../../assets/images/social/discord.png"
import instagramImg from "../../assets/images/social/instagram.png"
import linkedinImg from "../../assets/images/social/linkedin.png"
import redditImg from "../../assets/images/social/reddit.png"
import telegramImg from "../../assets/images/social/telegram.png"
import twitterImg from "../../assets/images/social/twitter.png"
import youtubeImg from "../../assets/images/social/youtube.png"

const Dashboard = props => {
    console.log(window.history.state.state,"state testing")
    const {
        Add,
        Edit,
        showEdit,
        handleShow,
        handelShow1,
        handleClose,
        setloading,
    } = useContext(LoaderContext)

    document.title = "Token Pairs"
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    let [incermentInd, setIncrementInd] = useState(1);
    const perPage = 50;

    //fetch users
    const fetchData = (pagenumber, pagelimit, search = "", status = "") => {
        try {
            setLoaderStatus(true)

            getListingOfTokenPair(pagenumber, pagelimit, search, "", "", "0", window.history.state.state).then(res => {
                console.log(res, "user listing >>>>>>")
                setData(res.data.items)

                let pageCount = Math.ceil(res.data.totalItems / perPage)
                setTotalCount(pageCount)
                setLoaderStatus(false)
            }).catch((error) => {
                fetchData(currentPage, perPage)
            })
        } catch (err) {
            setLoaderStatus(false)
        }
    }

    useEffect(() => {
        fetchData(currentPage, perPage)
    }, [window.history.state.state])

    const handlePageClick = page => {
        setCurrentPage(page)
        fetchData(page, perPage, searchName, statusFilter)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchClick = (e) => {

        const value = e.target.value;
        setSearchName(value);
        setCurrentPage(1)
        clearTimeout(typingTimeout);
        const timeoutId = setTimeout(() => {
            fetchData(1, perPage, e.target.value, statusFilter)
        }, 500);

        setTypingTimeout(timeoutId);

    }

    const [socialInfo, setSocialInfo] = useState(false)
    const [socialStatus, setSocialStatus] = useState(false)
    const handleSocialInfo = async (token) => {
        try {

            console.log(token, "<<--- handleSocialInfo")

            getSocialInfo(token).then((response) => {
                console.log("response --->>", response)
                setSocialInfo(response.data.socialInfo)
            })

        } catch (error) {
            console.log("Social Info Error ---->>", error)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div>
                                        <Row className="d-flex align-items-center">
                                            <Col lg="4">

                                                <h5 className=" text-color-gredient"> Token Pair of  {window.history.state.state == undefined ? ` ETH` : window.history.state.state?.toUpperCase()} </h5>
                                            </Col>
                                            <Col lg="8" className="d-flex align-items-center justify-content-end">

                                                <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                                    <div
                                                        className="position-relative"
                                                    >
                                                        <label htmlFor="search-bar-0" className="search-label mb-0">
                                                            <span id="search-bar-0-label" className="sr-only">
                                                                Search this table
                                                            </span>
                                                            <input
                                                                id="search-bar-0"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Search Name...`}
                                                                style={{ borderRadius: "4px" }}
                                                                onChange={handleSearchClick}
                                                            />
                                                        </label>
                                                        <i className="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>

                                                {/* <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                                    <select className="form-control select2 mb-3 mb-xxl-0" style={{ width: '165px' }} onChange={(e) => (setStatusFilter(e.target.value), fetchData(1, perPage, searchName, e.target.value))}>
                                                        <option value="">All Token</option>
                                                        <option value="eth">ETH</option>
                                                        <option value="base">Base</option>
                                                        <option value="solana">Solana</option>
                                                        <option value="bsc"> BSC </option>
                                                    </select>
                                                </div> */}
                                                {/* <Link to="#!" className="btn btn-primary me-1" onClick={refreshListing}><i className="mdi mdi-refresh"></i></Link> */}
                                            </Col>
                                        </Row>

                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>Token</Th>
                                                <Th>Token Address</Th>
                                                <Th>Pair Address</Th>
                                                <Th>Created On</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                (loaderStatus) ?
                                                    <Tr>
                                                        <Td colSpan="8"> Loading ... </Td>
                                                    </Tr>
                                                    :
                                                    listingData.length > 0 ? (
                                                        listingData.map((element, index) => {
                                                            (currentPage > 1) ? incermentInd = ((currentPage - 1) * perPage) + 1 : 0;
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span className="co-name"> {incermentInd + (index)} </span>
                                                                    </td>
                                                                    <td style={{maxWidth:'300px'}}>
                                                                        <h5 className="font-size-14 text-color-gredient">
                                                                            {element.token_name} ({element.token_symbol})
                                                                        </h5>
                                                                    </td>
                                                                    <td>
                                                                        {longStringConvertToShort(element.token_address, 30)}
                                                                        <a href={`https://${window.history.state.state == 'ether' ? 'etherscan.io' : window.history.state.state == 'bsc'? 'bscscan.com' : window.history.state.state == 'solana'? "solscan.io": window.history.state.state == 'base'? "basescan.org" : 'etherscan.io'}/token/${element.token_address}`} target="_blank" rel="noreferrer">
                                                                            <i className="mdi mdi-open-in-new ms-2"></i>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        {longStringConvertToShort(element.pair_address, 30)}
                                                                        <a href={`https://www.dextools.io/app/en/${window.history.state.state == 'ether' ? 'ether' : window.history.state.state == 'bsc'? 'bnb' : window.history.state.state == 'solana'? "solana": window.history.state.state == 'base'? "base" : 'ether'}/pair-explorer/${element.pair_address}`} target="_blank" rel="noreferrer">
                                                                            <i className="mdi mdi-open-in-new ms-2"></i>
                                                                        </a>
                                                                    </td>
                                                                    <td> {dateFormat(element.createdAt)} </td>
                                                                    <td> <button type="button" className="btn btn-primary btn-sm" onClick={() => (handleSocialInfo(element?.token_address), setSocialStatus(true))}> Social Info </button> </td>
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8}>No Records Found</td>
                                                        </tr>
                                                    )}

                                        </Tbody>
                                    </Table>

                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>


            <Modal
                isOpen={socialStatus}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0"> Social Info </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setSocialStatus(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {

                            !socialInfo?.discord && !socialInfo?.instagram && !socialInfo?.linkedin && !socialInfo?.reddit && !socialInfo?.telegram && !socialInfo?.twitter && !socialInfo?.youtube && <div className="col-12 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        Social Media Not Available
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            socialInfo?.discord && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.discord} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={discordImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> Discord </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.instagram && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.instagram} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={instagramImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> Instagram </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.linkedin && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.linkedin} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={linkedinImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> LinkedIn </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.reddit && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.reddit} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={redditImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> Reddit </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.telegram && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.telegram} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={telegramImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> Telegram </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.twitter && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.twitter} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={twitterImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> Twitter </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            socialInfo?.youtube && <div className="col-4 text-center d-flex justify-content-center align-items-center my-4">
                                <div className="avatar-group">
                                    <div className="avatar-group-item">
                                        <a href={socialInfo?.youtube} className="d-inline-block" target="_blank" rel="noreferrer">
                                            <img src={youtubeImg} alt="" className="rounded-circle avatar-xs" />
                                            <p className="my-2"> YouTube </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </Modal>
        </React.Fragment >
    )
}

Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
