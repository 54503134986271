import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Badge,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap";

import { alerShow } from "../commonFunction";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import "../../assets/scss/style.css";
import {
  changePassword,
  generalSettingUpdate,
  getGeneralInfo,
  getUserProfile,
  socialMediaSetting,
  updateProfile,
} from "services/userServices";
import { toast } from "react-toastify";
import { fireToast } from "common/toast";
import discordImg from "../../assets/images/social/discord.png";
import instagramImg from "../../assets/images/social/instagram.png";
import linkedinImg from "../../assets/images/social/linkedin.png";
import redditImg from "../../assets/images/social/reddit.png";
import telegramImg from "../../assets/images/social/telegram.png";
import twitterImg from "../../assets/images/social/twitter.png";
import youtubeImg from "../../assets/images/social/youtube.png";
import "./style.css";

const Dashboard = (props) => {
  //meta title
  document.title = "General Setting";
  const [mintable, setMintable] = useState(true);
  const [honeyPot, setHoneyPot] = useState(true);

  const [openSource, setOpenSource] = useState(true);
  const [proxy, setProxy] = useState(true);
  const [blacklisted, setBlacklisted] = useState(true);
  const [contractReannounced, setContractReannounced] = useState(true);
  const [potentiallyScam, setPotentiallyScam] = useState(true);

  const [discordStatus, setDiscordStatus] = useState(true);
  const [youtubeStatus, setYoutubeStatus] = useState(true);
  const [telegramStatus, setTelegramStatus] = useState(true);
  const [twitterStatus, setTwitterStatus] = useState(true);

  const [formValues, setFormValues] = useState({
    liquidity: "",
    holders: "",
    transactions: "",
    refresh_min: "",
    marketcap: "",
    variation: "",
    min_buytax: "",
    min_selltax: "",
    dextool_score: "",
  });
  const changeValues = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    console.log(formValues, "<<--- formValues");
  }, [formValues]);

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      liquidity: formValues.liquidity,
      holders: formValues.holders,
      transactions: formValues.transactions,
      refresh_min: formValues.refresh_min,
      marketcap: formValues.marketcap,
      variation: formValues.variation,
      min_buytax: formValues.min_buytax,
      min_selltax: formValues.min_selltax,
      dextool_score: formValues.dextool_score,
    },
    validationSchema: Yup.object({
      liquidity: Yup.number()
        .min(100, "Liquidity must be at least 100.")
        .max(5000000, "Liquidity cannot exceed 5000000.")
        .required("This field is required."),
      holders: Yup.number()
        .min(0, "Holders must be at least 0.")
        .max(2000, "Holders cannot exceed 2000.")
        .required("This field is required."),
      transactions: Yup.number()
        .min(0, "Transactions must be at least 0.")
        .max(5000000, "Transactions cannot exceed 5000000.")
        .required("This field is required."),
      refresh_min: Yup.number()
        .min(1, "Refresh Rate must be at least 1.")
        .max(50, "Refresh Rate cannot exceed 50.")
        .required("This field is required."),
      marketcap: Yup.number()
        .min(0, "Marketcap must be at least 0.")
        .max(5000000, "Marketcap cannot exceed 5000000.")
        .required("This field is required."),
      variation: Yup.number()
        .min(0, "Variation must be at least 0.")
        .max(100, "Variation cannot exceed 100.")
        .required("This field is required."),
      min_buytax: Yup.number()
        .min(0, "Variation must be at least 0.")
        .max(5000000, "Variation cannot exceed 5000000.")
        .required("This field is required."),
      min_selltax: Yup.number()
        .min(0, "Variation must be at least 0.")
        .max(5000000, "Variation cannot exceed 5000000.")
        .required("This field is required."),
      dextool_score: Yup.number()
        .min(0, "Variation must be at least 0.")
        .max(5000000, "Variation cannot exceed 5000000.")
        .required("This field is required."),
    }),
    onSubmit: (values, actions) => {
      console.log(values, "<<--- -values");
      generalSettingUpdate({
        ...values,
        mintable,
        honey_pot: honeyPot,
        openSource,
        proxy: proxy,
        contractReannounced: contractReannounced,
        potentiallyScam: potentiallyScam,
        blacklisted: blacklisted,
      })
        .then((response) => {
          if (response.status == 200) {
            fireToast("success", "Updated!");
          }
        })
        .catch((error) => {
          console.log(error, "<<--- error");
        });
    },
  });

  const getGenInfo = async () => {
    try {
      let detail = await getGeneralInfo();
      if (detail.status == 200) {
        setFormValues({
          liquidity: detail?.data?.liquidity,
          holders: detail?.data?.holders,
          transactions: detail?.data?.transactions,
          refresh_min: detail?.data?.refresh_min,
          marketcap: detail?.data?.marketcap,
          variation: detail?.data?.variation,
          min_buytax: detail?.data?.min_buytax,
          min_selltax: detail?.data?.min_selltax,
          dextool_score: detail?.data?.dextool_score,
        });
        setMintable(detail?.data?.mintable);
        setHoneyPot(detail?.data?.honey_pot);

        setBlacklisted(detail?.data?.blacklisted);
        setOpenSource(detail?.data?.openSource);
        setContractReannounced(detail?.data?.contractReannounced);
        setProxy(detail?.data?.proxy);
        setPotentiallyScam(detail?.data?.potentiallyScam);

        setDiscordStatus(detail?.data?.discord);
        setYoutubeStatus(detail?.data?.youtube);
        setTelegramStatus(detail?.data?.telegram);
        setTwitterStatus(detail?.data?.twitter);
      }
    } catch (error) {
      console.log(error, "error profile api");
    }
  };

  useEffect(() => {
    getGenInfo();
  }, []);

  const handleSocialMediaSetting = async () => {
    try {
      socialMediaSetting({
        discord: discordStatus,
        youtube: youtubeStatus,
        telegram: telegramStatus,
        twitter: twitterStatus,
      })
        .then((response) => {
          if (response.status == 200) {
            fireToast("success", "Updated!");
          }
        })
        .catch((error) => {
          console.log(error, "<<--- error");
        });
    } catch (error) {
      console.log(error, "error social media update api");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("profile")}
                    /> */}

          <Row>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4"> General Settings </CardTitle>

                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Liquidity </Label>
                          <Input
                            type="number"
                            name="liquidity"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.liquidity}
                          />
                          {validation.touched.liquidity &&
                          validation.errors.liquidity ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.liquidity}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Minimum Holders </Label>
                          <Input
                            type="number"
                            name="holders"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.holders}
                          />
                          {validation.touched.holders &&
                          validation.errors.holders ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.holders}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Minimum Transactions </Label>
                          <Input
                            type="number"
                            name="transactions"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.transactions}
                          />
                          {validation.touched.transactions &&
                          validation.errors.transactions ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.transactions}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Refresh Rate (Min) </Label>
                          <Input
                            type="number"
                            name="refresh_min"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.refresh_min}
                          />
                          {validation.touched.refresh_min &&
                          validation.errors.refresh_min ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.refresh_min}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Minimum Marketcap </Label>
                          <Input
                            type="number"
                            name="marketcap"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.marketcap}
                          />
                          {validation.touched.marketcap &&
                          validation.errors.marketcap ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.marketcap}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Variation 1 Hour (%) </Label>
                          <Input
                            type="number"
                            name="variation"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.variation}
                          />
                          {validation.touched.variation &&
                          validation.errors.variation ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.variation}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Minimum Sell Tax </Label>
                          <Input
                            type="number"
                            name="min_selltax"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.min_selltax}
                          />
                          {validation.touched.min_selltax &&
                          validation.errors.min_selltax ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.min_selltax}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Minimum Buy Tax </Label>
                          <Input
                            type="number"
                            name="min_buytax"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.min_buytax}
                          />
                          {validation.touched.min_buytax &&
                          validation.errors.min_buytax ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.min_buytax}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label> DexTool Score </Label>
                          <Input
                            type="number"
                            name="dextool_score"
                            className="form-control"
                            placeholder="Write here..."
                            onBlur={validation.handleBlur}
                            onChange={changeValues}
                            defaultValue={formValues.dextool_score}
                          />
                          {validation.touched.dextool_score &&
                          validation.errors.dextool_score ? (
                            <FormFeedback type="invalid">
                              {" "}
                              {validation.errors.dextool_score}{" "}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 formSettings">
                              {" "}
                              Honey Pot :{" "}
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={honeyPot}
                                onClick={() => setHoneyPot(!honeyPot)}
                              />
                              <label
                                htmlFor="square-switch"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 formSettings">
                              {" "}
                              Mintable :{" "}
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch1"
                                switch="none"
                                checked={mintable}
                                onClick={() => setMintable(!mintable)}
                              />
                              <label
                                htmlFor="square-switch1"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex ">
                            <Label className="me-2 formSettings">
                              {" "}
                              Reannounced :{" "}
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch2"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={contractReannounced}
                                onClick={() =>
                                  setContractReannounced(!contractReannounced)
                                }
                              />
                              <label
                                htmlFor="square-switch2"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 formSettings">
                              {" "}
                              BlackListed :{" "}
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch3"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={blacklisted}
                                onClick={() => setBlacklisted(!blacklisted)}
                              />
                              <label
                                htmlFor="square-switch3"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 d-flex formSettings">
                              Proxy :
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch4"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={proxy}
                                onClick={() => setProxy(!proxy)}
                              />
                              <label
                                htmlFor="square-switch4"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 d-flex formSettings">
                              Potentially Scam :
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch5"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={potentiallyScam}
                                onClick={() =>
                                  setPotentiallyScam(!potentiallyScam)
                                }
                              />
                              <label
                                htmlFor="square-switch5"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3 mt-4">
                          <div className="d-flex">
                            <Label className="me-2 d-flex formSettings">
                              OpenSource :
                            </Label>
                            <div className="square-switch">
                              <input
                                type="checkbox"
                                id="square-switch6"
                                switch="none"
                                // defaultChecked={honeyPot}
                                checked={openSource}
                                onClick={() => setOpenSource(!openSource)}
                              />
                              <label
                                htmlFor="square-switch6"
                                data-on-label="Yes"
                                data-off-label="No"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button
                        type="submit"
                        className="btn btn-secondary w-md mt-3"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">
                    {" "}
                    Social Media Settings{" "}
                  </CardTitle>

                  <Form
                    className="needs-validation create-vendor"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSocialMediaSetting();
                      return false;
                    }}
                  >
                    <Row className="mb-0 row py-5">
                      <Col md={6}>
                        <div className="mb-3">
                          <div className="text-center justify-content-center align-items-center my-4">
                            <div className="avatar-group d-flex justify-content-center align-items-center">
                              <div className="avatar-group-item ">
                                <div className="avatar-sm me-3">
                                  <span className="bg-primary bg-soft  avatar-title rounded-circle">
                                    <img
                                      src={discordImg}
                                      alt=""
                                      className="rounded-circle avatar-xs"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3 justify-content-center align-items-center">
                              <Label
                                className="me-2"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                Discord :{" "}
                              </Label>
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch8"
                                  switch="none"
                                  checked={discordStatus}
                                  onClick={() =>
                                    setDiscordStatus(!discordStatus)
                                  }
                                />
                                <label
                                  htmlFor="square-switch8"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <div className="text-center justify-content-center align-items-center my-4">
                            <div className="avatar-group d-flex justify-content-center align-items-center">
                              <div className="avatar-group-item ">
                                <div className="avatar-sm me-3">
                                  <span className="bg-danger bg-soft  avatar-title rounded-circle">
                                    <img
                                      src={youtubeImg}
                                      alt=""
                                      className="rounded-circle avatar-xs"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3 justify-content-center align-items-center">
                              <Label
                                className="me-2"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                YouTube :{" "}
                              </Label>
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch9"
                                  switch="none"
                                  checked={youtubeStatus}
                                  onClick={() =>
                                    setYoutubeStatus(!youtubeStatus)
                                  }
                                />
                                <label
                                  htmlFor="square-switch9"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <div className="text-center justify-content-center align-items-center my-4">
                            <div className="avatar-group d-flex justify-content-center align-items-center">
                              <div className="avatar-group-item ">
                                <div className="avatar-sm me-3">
                                  <span className="bg-info bg-soft  avatar-title rounded-circle">
                                    <img
                                      src={telegramImg}
                                      alt=""
                                      className="rounded-circle avatar-xs"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3 justify-content-center align-items-center">
                              <Label
                                className="me-2"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                Telegram :{" "}
                              </Label>
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch10"
                                  switch="none"
                                  checked={telegramStatus}
                                  onClick={() =>
                                    setTelegramStatus(!telegramStatus)
                                  }
                                />
                                <label
                                  htmlFor="square-switch10"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <div className="text-center justify-content-center align-items-center my-4">
                            <div className="avatar-group d-flex justify-content-center align-items-center">
                              <div className="avatar-group-item ">
                                <div className="avatar-sm me-3">
                                  <span className="bg-secondary bg-soft  avatar-title rounded-circle">
                                    <img
                                      src={twitterImg}
                                      alt=""
                                      className="rounded-circle avatar-xs"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-3 justify-content-center align-items-center">
                              <Label
                                className="me-2"
                                style={{ fontSize: "16px" }}
                              >
                                {" "}
                                Twitter :{" "}
                              </Label>
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch11"
                                  switch="none"
                                  checked={twitterStatus}
                                  onClick={() =>
                                    setTwitterStatus(!twitterStatus)
                                  }
                                />
                                <label
                                  htmlFor="square-switch11"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-2">
                      <button
                        type="submit"
                        className="btn btn-secondary w-md mt-t"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
