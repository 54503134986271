import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import HRNumbers from "human-readable-numbers"

const LiquidityInfoModal = props => {
  const { isOpen, toggle ,data} = props
  console.log(data);
  
  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format('MMM DD, YYYY')}
        <br />
        <small>{moment(date).format('h:mm a')}</small>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg" style={{maxWidth: '700px', width: '100%'}}
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{data.name} Liquidity Info</ModalHeader>
        <ModalBody>
          <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                <Thead>
                                    <Tr>
                                    <Th scope="col">Liquidity</Th>
                                    <Th scope="col">Main Token</Th>
                                    <Th scope="col">Side Token</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                
                                    <td>
                                        <div>
                                        <p> {data.liquidity ? (HRNumbers.toHumanString( data.liquidity)) : ""} </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.mainToken ? (HRNumbers.toHumanString( data.mainToken)) : ""} ({data.name}) </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.sideToken ? (HRNumbers.toHumanString( data.sideToken)) : ""} ({data.sideTokenname}) </p>  
                                        </div>
                                    </td>
                               
                                   
                                </Tbody>
                                </Table>
          </div>

        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

LiquidityInfoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data:PropTypes.object
}

export default LiquidityInfoModal
