import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
    Badge,
    Card,
    CardBody,
    Col,
    Row,
    Button
} from "reactstrap"
import "../../../assets/scss/style.css"

import "flatpickr/dist/themes/material_blue.css"

import { withTranslation } from "react-i18next"

import { handleClickCopy, longStringConvertToShort } from "admin/commonFunction"
import { LoaderContext } from "context/ContextProvider"
import Pagination, {
    bootstrap5PaginationPreset,
} from "react-responsive-pagination"
import { getListingOfTokenPair } from "services/userServices"
import HRNumbers from "human-readable-numbers"
import { dateFormat } from "common/utility"
import axios from "axios"
import { get } from "helpers/api_helper"
import SocialModal from "./SocialModal"
import DeleteModal from "components/Common/DeleteModal"
import GeneralInfoModal from "./GeneralInfoModal"
import AuditInfoModal from "./AuditInfoModal"
import LiquidityInfoModal from "./LiquidityInfoModal"


const HotPair = props => {

    document.title = "Hot Token Pairs"
    const [dateFilter, setDateFilter] = useState([])
    const [listingData, setData] = useState([])
    const [liquidityId, setLiquidityId] = useState("")
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);

    const [generalInfo, setGeneralInfo] = useState({
        name:"",
        fdv:"",
        holders:"",
        mcap:"",
        totalSupply:"",
        transactions:""
    });
    const [liquidityInfo, setLiquidityInfo] = useState({
        name:"",
        sideTokenname:"",
        liquidity:"",
        mainToken:"",
        sideToken:"",
    });
    const [auditInfo, setAuditInfo] = useState({
        name:"",
        openSource:"",
        honeyPot:"",
        mintable:"",
        proxy:"",
        slippageModifable:"",
        blacklisted:"",
        sellMinTax:"",
        sellMaxTax:"",
        buyMinTax:"",
        buyMaxTax:"",
        contractReannounced:"",
        potentiallyScam:""
    });
    const [socialData, setSocialData] = useState({
        bitbucket: "",
        discord: "",
        facebook: "",
        github: "",
        instagram: "",
        linkedin: "",
        medium: "",
        reddit: "",
        telegram: "",
        tiktok: "",
        twitter: "",
        website: "",
        youtube: ""
    });
    const [item, setItem] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [searchName, setSearchName] = useState("")
    const [statusFilter, setStatusFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [loaderStatus, setLoaderStatus] = useState(true)
    let [incermentInd, setIncrementInd] = useState(1);
    const perPage = 50;

    const toggleViewModal = (item) => {
        setModal1(!modal1)
        fetchSocialData(item.mainToken.address)
    };
    const toggleInfoModal = (item) => {
        setModal2(!modal2)
        fetchGeneralInfo(item.mainToken.address,item.mainToken.name)
    };
    const toggleAuditInfo = (item) => {
        setModal3(!modal3)
        fetchAuditInfo(item.mainToken.address,item.mainToken.name)
    };
    const toggleLiquidityInfo = (item) => {
        setModal4(!modal4)
        fetchLiquidityInfo(item.address,item.mainToken.name,item.sideToken.symbol)
    };
    //fetch users
    const fetchData = async () => {
        try {
            setLoaderStatus(true)
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

            const response = await get(`/auth/getEthPair`, { headers });
            setData(response?.data?.data)
        } catch (err) {
            setLoaderStatus(false)
            // fetchData(currentPage, perPage)
        }
    }

    const fetchLiquidityInfo = async (item,name,sideTokenname) => {
        setLiquidityInfo({})
        try {
            setLoaderStatus(true)
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

            const response = await get(`/auth/getLiquidityData?chain=ether&poolAddress=${item}`, { headers });
            console.log(response?.data);
            setLiquidityInfo( 
            {
            name:name,
            sideTokenname:sideTokenname,
            liquidity:response?.data?.data?.liquidity,
            mainToken:response?.data?.data?.reserves.mainToken,
            sideToken:response?.data?.data?.reserves.sideToken,
        }
        )
            
        } catch (err) {
            setLoaderStatus(false)
            // fetchData(currentPage, perPage)
        }
    }

    const fetchAuditInfo = async (address,name) => {
        // setAuditInfo({})
        try {
            setLoaderStatus(true)
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

            const response = await get(`/auth/getAuditInfo?chain=ether&address=${address}`, { headers });
            console.log(response?.data?.data);
            setAuditInfo({
                name:name,
                openSource:response?.data?.data?.isOpenSource,
                honeyPot:response?.data?.data?.isHoneypot,
                mintable:response?.data?.data?.isMintable,
                proxy:response?.data?.data?.isProxy,
                slippageModifable:response?.data?.data?.slippageModifiable,
                blacklisted:response?.data?.data?.isBlacklisted,
                sellMinTax:response?.data?.data?.sellTax.min,
                sellMaxTax:response?.data?.data?.sellTax.max,
                buyMinTax:response?.data?.data?.buyTax.min,
                buyMaxTax:response?.data?.data?.buyTax.max,
                contractReannounced:response?.data?.data?.isContractRenounced,
                potentiallyScam:response?.data?.data?.isPotentiallyScam
            })

        } catch (err) {
            setLoaderStatus(false)
            // fetchData(currentPage, perPage)
        }
    }
    const fetchGeneralInfo = async (address,name) => {
        setGeneralInfo({})
        try {
            setLoaderStatus(true)
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

            const response = await get(`/auth/getGeneralInfoToken?chain=ether&address=${address}`, { headers });
            console.log(response?.data?.data);
            setGeneralInfo({
                name:name,
                // sideToken:name,
                fdv:response?.data?.data?.fdv,
                holders:response?.data?.data?.holders,
                mcap:response?.data?.data?.mcap,
                totalSupply:response?.data?.data?.totalSupply,
                transactions:response?.data?.data?.transactions 
            })

        } catch (err) {
            setLoaderStatus(false)
            // fetchData(currentPage, perPage)
        }
    }
    const fetchSocialData = async (address) => {
        try {
            setLoaderStatus(true)
            const token = JSON.parse(localStorage.getItem("authUser")).token;
            const headers = { Authorization: token };

            const response = await get(`/auth/getSocialData?chain=ether&address=${address}`, { headers });
            setSocialData({
                bitbucket: response?.data?.data?.socialInfo.bitbucket,
                discord: response?.data?.data?.socialInfo.discord,
                facebook: response?.data?.data?.socialInfo.facebook,
                github: response?.data?.data?.socialInfo.github,
                instagram: response?.data?.data?.socialInfo.instagram,
                linkedin: response?.data?.data?.socialInfo.linkedin,
                medium: response?.data?.data?.socialInfo.medium,
                reddit: response?.data?.data?.socialInfo.reddit,
                telegram: response?.data?.data?.socialInfo.telegram,
                tiktok: response?.data?.data?.socialInfo.tiktok,
                twitter: response?.data?.data?.socialInfo.twitter,
                website: response?.data?.data?.socialInfo.website,
                youtube: response?.data?.data?.socialInfo.youtube
            })
        } catch (err) {
            setLoaderStatus(false)
            // fetchData(currentPage, perPage)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <React.Fragment>
            <SocialModal isOpen={modal1} toggle={toggleViewModal} data={socialData} />
            <GeneralInfoModal isOpen={modal2} toggle={toggleInfoModal} data={generalInfo} />
            <AuditInfoModal isOpen={modal3} toggle={toggleAuditInfo} data={auditInfo} />
            <LiquidityInfoModal isOpen={modal4} toggle={toggleLiquidityInfo} data={liquidityInfo} />
            <DeleteModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div>
                                        <Row className="d-flex align-items-center">
                                            <Col lg="4">

                                                <h5 className=" text-color-gredient">ETH Pair</h5>
                                            </Col>
                                            <Col lg="8" className="d-flex align-items-center justify-content-end">

                                                <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                                                    <div
                                                        className="position-relative"
                                                    >
                                                        <label htmlFor="search-bar-0" className="search-label mb-0">
                                                            <span id="search-bar-0-label" className="sr-only">
                                                                Search this table
                                                            </span>
                                                            <input
                                                                id="search-bar-0"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={`Search Name...`}
                                                                style={{ borderRadius: "4px" }}
                                                            // onChange={handleSearchClick}
                                                            />
                                                        </label>
                                                        <i className="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>

                                    </div>
                                </CardBody>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Sr. No.</Th>
                                                <Th>Token</Th>
                                                {/* <Th>Token Address</Th> */}
                                                <Th>Pool Address</Th>
                                                {/* <Th>Liquidity</Th> */}
                                                <Th>Rank</Th>
                                                {/* <Th>Side Token Name</Th> */}
                                                {/* <Th>Transactions</Th> */}
                                                {/* <Th>Price</Th> */}
                                                {/* <Th>Exchange Name</Th> */}
                                                <Th>Created On</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                (false) ?
                                                    <Tr>
                                                        <Td colSpan="8"> Loading ... </Td>
                                                    </Tr>
                                                    :
                                                    listingData.length > 0 ? (
                                                        listingData.map((element, index) => {
                                                            (currentPage > 1) ? incermentInd = ((currentPage - 1) * perPage) + 1 : 0;
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span className="co-name"> {incermentInd + (index)} </span>
                                                                    </td>
                                                                    <td>
                                                                        <strong>  <p className="font-size-14 text-color-gredient">
                                                                            {element.mainToken.symbol} - {element.sideToken.symbol}
                                                                        </p>
                                                                        </strong>
                                                                        <p className="my-2">



                                                                            {/* <label className="mb-0">Token : {element.mainToken.name}</label>{" "} */}
                                                                            {longStringConvertToShort(element.mainToken.address, 30)}
                                                                            <a href={`https://etherscan.io/address/${element.mainToken.address}`} target="_blank" rel="noreferrer">
                                                                                <i className="mdi mdi-open-in-new ms-2"></i>
                                                                            </a>

                                                                            <b className="mb-0">

                                                                                <i
                                                                                    role="button"
                                                                                    onClick={() =>
                                                                                        handleClickCopy(element.mainToken.address)
                                                                                    }
                                                                                    className="mdi mdi-content-copy ms-2"
                                                                                ></i>{" "}
                                                                            </b>
                                                                        </p>


                                                                    </td>
                                                                    {/* <td>
                                                                      
                                                                    </td> */}

                                                                    <td>
                                                                        {element.exchange.name}
                                                                        <p className="my-1">
                                                                            {/* <label className="mb-0">Token : {element.mainToken.name}</label>{" "} */}
                                                                            {longStringConvertToShort(element.address, 30)}
                                                                            <a href={`https://www.dextools.io/app/en/ether/pair-explorer/${element.address}`} target="_blank" rel="noreferrer">
                                                                                <i className="mdi mdi-open-in-new ms-2"></i>
                                                                            </a>

                                                                            <b className="mb-0">

                                                                                <i
                                                                                    role="button"
                                                                                    onClick={() =>
                                                                                        handleClickCopy(element.address)
                                                                                    }
                                                                                    className="mdi mdi-content-copy ms-2"
                                                                                ></i>{" "}
                                                                            </b>

                                                                        </p>
                                                                    </td>
                                                                    {/* 
                                                                    <td> {element.mainToken.name}({element.mainToken.symbol}) </td>
                                                                    <td> {element.sideToken.name}({element.sideToken.symbol}) </td> */}
                                                                    {/* <td> {element?.transactions ? element?.transactions : "-"} </td> */}
                                                                    {/* <td> {element?.price ? element?.price : "-"} </td> */}

                                                                    {/* <td className="text-end">
                                                                    <p> {liquidityData ?(liquidityId == element.address? HRNumbers.toHumanString( liquidityData) : "") : ""} </p> 
                                                                                   <button className="btn btn-primary  px-1 py-1" onClick={()=>{handleRefresh(element)}} >
                                                                                   <i className="bx bx-rotate-right" style={{
                                                                                        fontSize:"20px"
                                                                                    }}></i>
                                                                                    </button>
                                                                  
                                                                        
                                                                    </td> */}
                                                                    <td>{element.rank}</td>
                                                                    <td>
                                                                        {dateFormat(element.createdAt)}
                                                                    </td>

                                                                    <td> 
                                                                        <button type="button" className="btn btn-primary btn-sm ms-2"
                                                                        onClick={() => toggleViewModal(element)}
                                                                    // setSocialStatus(true))}
                                                                    > Social Info </button> 

                                                                        <button type="button" className="btn btn-primary btn-sm ms-2"
                                                                        onClick={() => toggleInfoModal(element)}
                                                                    // setSocialStatus(true))}
                                                                    >General Info</button> 

                                                                        <button type="button" className="btn btn-primary btn-sm ms-2"
                                                                        onClick={() => toggleAuditInfo(element)}
                                                                    // setSocialStatus(true))}
                                                                    >Audit Info</button> 

                                                                        <button type="button" className="btn btn-primary btn-sm ms-2"
                                                                        onClick={() => toggleLiquidityInfo(element)}
                                                                    // setSocialStatus(true))}
                                                                    >Liquidity Info</button>

                                                                    </td>

                                                                    {/* <td>
                                                                        {
                                                                            element.status < 0 ? <Badge className="bg-danger"> Rejected </Badge> :
                                                                                element.status == 1 ? <Badge className="bg-primary"> Level 1 </Badge> :
                                                                                    element.status == 2 ? <Badge className="bg-primary"> Level 2 </Badge> :
                                                                                        element.status == 3 ? <Badge className="bg-primary"> Level 3 </Badge> :
                                                                                            element.status == 4 ? <Badge className="bg-success"> Level 4 </Badge> :
                                                                                                element.status == 5 ? <Badge className="bg-success"> Verified </Badge> :
                                                                                                    <Badge className="bg-warning">Pending</Badge>
                                                                        }
                                                                    </td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={9}>No Records Found</td>
                                                        </tr>
                                                    )}

                                        </Tbody>
                                    </Table>

                                    <Row className="mt-2">
                                        <Col md={9}></Col>
                                        <Col md={3}>
                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalCount}
                                                onPageChange={page => handlePageClick(page)}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}

HotPair.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
}

export default withTranslation()(HotPair)
