import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import "./style.css"
import img from "./404.gif"

const Pages404 = () => {
  //meta title
  document.title = "404 Error!";

  return (
    <React.Fragment>
      <div id="notfound">
        <div className="notfound">
          <img src={img} style={{ width: 350 }} />
          <div className="notfound-404">
            <h1>Oops!</h1>
            <h2>404 - The Page can't be found</h2>
          </div>
          <a href="/admin/dashboard">Go TO Homepage</a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Pages404
