import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link, Redirect } from "react-router-dom"
import { useHistory } from "react-router";

//i18n
import { withTranslation } from "react-i18next"
import { toastConfirm } from "common/toast"

const SidebarContent = props => {
  const ref = useRef()

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }



  const history = useHistory();
  const handleLogout = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Log Out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout it!",
    }).then(result => {
      if (result.isConfirmed) {
        history.push("/logout");
      }
    })
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i>{" "}
              {props.t("Dashboard")}{" "}
            </li>

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i>{" "}
              {props.t("Tokens")}
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                  <i className="bx bxs-hot"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Token Pairs")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/dashboard", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/dashboard`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/dashboard", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/dashboard", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                  <i className="mdi mdi-close-octagon-outline"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Rejected Tokens")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/rejected", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/token/rejected`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/rejected", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/rejected", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                <i className="mdi mdi-sync"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Level 0 ")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level0", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/token/level0`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level0", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level0", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                <i className="mdi mdi-sync"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Level 1 ")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level1", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/token/level1`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level1", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level1", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                <i className="mdi mdi-sync"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Level 2 ")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level2", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/token/level2`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level2", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/token/level2", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            <li>
              <Link to='/#'>
                <li className='' >
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Gem Tokens")}</span>
                </li>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link className={`${window.history?.state?.state == 'ether' ? 'mm-active' : ""}`} to={{ pathname: "/admin/gem/tokens", state: "ether" }} >{props.t("ETH")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'bsc' ? 'mm-active' : ""}`} to={{ pathname: `/admin/gem/tokens`, state: "bsc" }}>{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'solana' ? 'mm-active' : ""}`} to={{ pathname: "/admin/gem/tokens", state: "solana" }}>{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link className={`${window.history?.state?.state == 'base' ? 'mm-active' : ""}`} to={{ pathname: "/admin/gem/tokens", state: "base" }}>{props.t("Base")}</Link>
                </li>
              </ul>
            </li>


            {/* 
            <li>
              <Link to="/admin/underprogress">
                <i className="mdi mdi-sync"></i>
                <span>{props.t("Under Progress")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/rejected">
                <i className="mdi mdi-close-octagon-outline"></i>
                <span>{props.t("Rejected Token")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/gems">
                <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                <span>{props.t("Gems Token")}</span>
              </Link>
            </li> */}

            <li>

              <Link to='/#'>
                <li className='' >
                  <i className="bx bxs-hot"></i>
                  <span className="has-arrow mm-collapsed">{props.t("Hot Pairs")}</span>
                </li>

              </Link>


              <ul className="sub-menu">
                <li>
                  <Link to="/eth">{props.t("ETH")}</Link>
                </li>

                <li>
                  <Link to="/bsc">{props.t("BSC")}</Link>
                </li>
                <li>
                  <Link to="/solana">{props.t("Solana")}</Link>
                </li>
                <li>
                  <Link to="/base">{props.t("Base")}</Link>
                </li>
              </ul>

            </li>


            <li>

            <Link to='/#'>
              <li className='' >
                <i className="bx bx-wallet-alt"></i>
                <span className="has-arrow mm-collapsed">{props.t("Top Wallets")}</span>
              </li>

            </Link>


            <ul className="sub-menu">
              <li>
                <Link to="/w-eth">{props.t("WETH")}</Link>
              </li>

              <li>
                <Link to="/usdt">{props.t("USDT")}</Link>
              </li>
              <li>
                <Link to="/usdc">{props.t("USDC")}</Link>
              </li>
              <li>
                <Link to="/bnb">{props.t("WBNB")}</Link>
              </li>
            </ul>

            </li>


            {/* <li className="active mm-active">
              <Link to="/" className="has-arrow">
                <i className="bx bx-customize"></i>
                <span>{props.t("Deals / Bonds")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/deals" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin/deals/create" >
                    <i className="bx bx-list-ul"></i>
                    <span>{props.t("Add New")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/admin/liquidity-pools">
                <i className="bx bx-customize"></i>
                <span>{props.t("Liquidity Pools")}</span>
              </Link>
            </li> */}

            <li className="menu-title">
              <i className="bx bx-buoy font-size-12"></i>{" "}
              {props.t("Setting")}
            </li>
            {/* 
            <li>
              <Link to="/admin/csm-pages">
                <i className="bx bx-customize"></i>
                <span>{props.t("CMS pages")}</span>
              </Link>
            </li>
            
            <li>
              <Link to="/admin/faqs">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("FAQ")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/admin/general">
                <i className="mdi mdi-cog-refresh"></i>
                <span>{props.t("General")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/profile">
                <i className="bx bx-user"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>

            <li>
              <Link onClick={handleLogout} className="" to={"#"}>
                <i className="bx bx-power-off"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>
          </ul>

        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
