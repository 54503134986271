import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import HRNumbers from "human-readable-numbers"

const AuditInfoModal = props => {
  const { isOpen, toggle ,data} = props
  console.log(data);
  
  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format('MMM DD, YYYY')}
        <br />
        <small>{moment(date).format('h:mm a')}</small>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg" style={{maxWidth: '1000px', width: '100%'}}
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{data.name} Audit Info</ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            User Name: <span className="text-primary">{data.name}</span>
          </p>
          <p className="mb-4">
            User Email: <span className="text-primary">{data.email}</span>
          </p> */}

          <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                <Thead>
                                    <Tr>
                                    <Th scope="col">HoneyPot</Th>
                                    <th scope="col">OpenSource</th>
                                    <Th scope="col">Mintable</Th>
                                    <Th scope="col">Proxy</Th>
                                    <Th scope="col">SlippageModifable</Th>
                                    <Th scope="col">BlackListed</Th>
                                    <Th scope="col">ContractReannounced</Th>
                                    <Th scope="col">PotentiallyScam</Th>
                                    </Tr>
                                </Thead>
                                <Tbody className="text-center">
                                
                                    <td>
                                        <div>
                                        <p> {data.honeyPot.charAt(0).toUpperCase() + data.honeyPot.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.openSource.charAt(0).toUpperCase() + data.openSource.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.mintable.charAt(0).toUpperCase() + data.mintable.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.proxy.charAt(0).toUpperCase() + data.proxy.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.slippageModifable.charAt(0).toUpperCase() + data.slippageModifable.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.blacklisted.charAt(0).toUpperCase() + data.blacklisted.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.contractReannounced.charAt(0).toUpperCase() + data.contractReannounced.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                        <p> {data.potentiallyScam.charAt(0).toUpperCase() + data.potentiallyScam.slice(1).toLowerCase()}  </p> 
                                        </div>
                                    </td>
                                   
                                </Tbody>
                                </Table>
          </div>

          <div className="d-flex">
    <div className="table-responsive w-50">
        <Table className="table align-middle table-nowrap">
            <Thead>
                <Tr>
                    <Th scope="col">Buy Taxes</Th>
                </Tr>
            </Thead>
            <Tbody>
                <div>
                    <p>Min : {data.buyMinTax}</p> 
                </div>
                <div>
                    <p>Max : {data.buyMaxTax}</p> 
                </div>
            </Tbody>
        </Table>
    </div>
    <div className="table-responsive w-50">
        <Table className="table align-middle table-nowrap">
            <Thead>
                <Tr>
                    <Th scope="col">Sell Taxes</Th>
                </Tr>
            </Thead>
            <Tbody>
                <div>
                    <p>Min :  {data.sellMinTax} </p> 
                </div>
                <div>
                    <p>Max :  {data.sellMinTax} </p> 
                </div>
            </Tbody>
        </Table>
    </div>
</div>

        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

AuditInfoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data:PropTypes.object
}

export default AuditInfoModal
