import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"

const SocialModal = props => {
  const { isOpen, toggle ,data} = props

  return (
  
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Social Details</ModalHeader>
        <ModalBody>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                
                <tr>
                 
                    {data.telegram=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Telegram :</h5>
                      <a href={data.telegram} target="_blank" rel="noreferrer"  style={{color:"blue"}}>
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.telegram}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                     
                      </a>
                   
                    </div>}
                 
                </tr>
                
                <tr>
                 
                {data.facebook=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Facebook :</h5>
                      <a href={data.facebook} target="_blank" rel="noreferrer" style={{color:"blue"}}>
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.facebook}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                      </a>
                   
                    </div>}
                  
                </tr>
                
                <tr> 
                {data.github=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Github :</h5>
                      <a href={data.github} target="_blank" rel="noreferrer">
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.github}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                      </a>
                   
                    </div>}
                </tr>
                
                <tr> 
                {data.bitbucket=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Bitbucket :</h5>
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.bitbucket}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                      <a href={data.bitbucket} target="_blank" rel="noreferrer">
                      </a>
                   
                    </div>}
                </tr>
                
                <tr> 
                {data.discord=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Discord :</h5>
                      <a href={data.discord} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.discord}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                
                <tr> 
                {data.instagram=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14" style={{minWidth:"85px"}}>Instagram :</h5>
                      <a href={data.instagram} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.instagram}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                
                <tr> 
                {data.linkedin=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Linkedin :</h5>
                      <a href={data.linkedin} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.linkedin}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.medium=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Medium :</h5>
                      <a href={data.medium} target="_blank" rel="noreferrer">
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.medium}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.reddit=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Reddit :</h5>
                      <a href={data.reddit} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.reddit}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.tiktok=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Tiktok :</h5>
                      <a href={data.tiktok} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.tiktok}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.twitter=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14">Twitter :</h5>
                      <a href={data.twitter} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.twitter}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                     
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.website=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14" style={{minWidth:"70px"}}>Website :</h5>

                      <a href={data.website} target="_blank" rel="noreferrer">
                        <div className="d-flex">
                        <p className="text-muted mb-0">{data.website}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                        </div>
                    
                      </a>
                   
                    </div>}
                </tr>
                <tr> 
                {data.youtube=="" ? "" : <div className="d-flex gap-4">
                      <h5 className="text-truncate font-size-14" style={{minWidth:"70px"}} >Youtube :</h5>
                      <a href={data.youtube} target="_blank" rel="noreferrer">
                      <div className="d-flex">
                      <p className="text-muted mb-0">{data.youtube}</p>
                      <i className="mdi mdi-open-in-new ms-2"></i>
                      </div>
                    
                      </a>
                   
                    </div>}
                </tr>

              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

SocialModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data:PropTypes.object
}

export default SocialModal
