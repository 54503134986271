import React from "react";
import moment from "moment";
const HRNumbers = require("approximate-number");
export const StandardPickerDateFormat = (Date) => {
  return moment(Date).format("MMM DD, YYYY");
};

export const fullDateFormat = (date) => {
  return (
    <>
      {moment(date).format("MMMM DD, YYYY")}
      <br />
      <small>{moment(date).format("h:mm a")}</small>
    </>
  );
};

export const dateFormat = (date) => {
  return (
    <>
      {moment(date).format("DD MMM, YYYY")}
      <p className="mb-0">{moment(date).format("h:mm a")}</p>
    </>
  );
};

export const downloadFile = (dataurl, filename) => {
  const a = document.createElement("a");
  a.href = dataurl;
  a.setAttribute("download", filename);
  a.click();
};

export const monthDayYearFormat = (date) => {
  return <>{moment(date).format("MMMM DD, YYYY")}</>;
};

export default function appendData(values) {
  const formValues = { ...values };
  const formData = new FormData();
  for (const value in formValues) {
    formData.append(value, formValues[value]);
  }
  return formData;
}
export const humanReadableNumber = (number) => {
  return HRNumbers(number, { capital: true });
};

export const walletAddressShort = (string) => {
  return string ? string.slice(0, 5) + '...' + string.slice(string.length - 5) : ""
};

export const dateStringToDate = (timestamp) => {
  const date = moment.unix(timestamp).format("DD MMM, YYYY");
  return date;
};
