import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Badge, Card, CardBody, Col, Row, Button } from "reactstrap";
import "../../../assets/scss/style.css";

import "flatpickr/dist/themes/material_blue.css";

import { withTranslation } from "react-i18next";

import {
  handleClickCopy,
  longStringConvertToShort,
} from "admin/commonFunction";
import { LoaderContext } from "context/ContextProvider";
import { bootstrap5PaginationPreset } from "react-responsive-pagination";
import { get } from "helpers/api_helper";
import Pagination from "common/Pagination";
import HRNumbers from "human-readable-numbers";
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { fireToast } from "common/toast";

const USDC = (props) => {
  document.title = "WETH Top Wallets";
  const [dateFilter, setDateFilter] = useState([]);
  const [listingData, setData] = useState([]);
  const [liquidityId, setLiquidityId] = useState("");
  const [item, setItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false)
  let [incermentInd, setIncrementInd] = useState(1);
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 40,
  });

  const perPage = 50;

  const [file, setFile] = useState(null);
    // Function to handle file input change
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSaveButton = async () => {
      try {
        setShow(false);
        // const token = JSON.parse(localStorage.getItem("authUser")).token;
        // const headers = { Authorization: token };
        var formData = new FormData()
        formData.append("csv", file)
        fireToast("success", "Uploaded!");
        const response = await post(`/auth/addTopWalletsUsdcData`,formData);
        console.log(response?.data);
        fireToast("success", "Uploaded!");
      } catch (err) {
        setLoaderStatus(false);
      }
    };

  const fetchData = async (pageNumber, pageSize) => {
    try {
      const token = JSON.parse(localStorage.getItem("authUser")).token;
      const headers = { Authorization: token };

      const response = await get(`/auth/getTopWalletHolders?type=USDC`, {
        headers,
      });
      setData(response?.data);
    } catch (err) {
      setLoaderStatus(false);
      // fetchData(currentPage, perPage)
    }
  };

  useEffect(() => {
    fetchData(page.current, page.pageSize);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom py-2">
                  <div>
                    <Row className="d-flex align-items-center">
                      <Col lg="4">
                        <h5 className=" text-color-gredient">USDC Wallets</h5>
                      </Col>
                      <Col
                        lg="8"
                        className="d-flex align-items-center justify-content-end"
                      >

                      <Button
                        size="lg"
                        className=" px-2 w-15 py-2 me-3 btn-primary"
                        name="csv"
                        onClick={() => setShow(true)}
                        
                      >
                        <i  className="bx bx-export" />{' '}
                        Import CSV
                      </Button>
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label mb-0"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`Search Name...`}
                                style={{ borderRadius: "4px" }}
                                // onChange={handleSearchClick}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Asset</Th>
                        <Th>Wallet Address</Th>
                        <Th>Balance</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {false ? (
                        <Tr>
                          <Td colSpan="8"> Loading ... </Td>
                        </Tr>
                      ) : listingData.length > 0 ? (
                        listingData.map((element, index) => {
                          currentPage > 1
                            ? (incermentInd = (currentPage - 1) * perPage + 1)
                            : 0;
                          return (
                            <tr key={index}>
                              <td>
                                <span className="co-name">
                                  {" "}
                                  {incermentInd + index}{" "}
                                </span>
                              </td>
                              <td>
                                <strong>
                                  {" "}
                                  <p className="font-size-14 text-color-gredient">
                                    USDC
                                  </p>
                                </strong>
                              </td>

                              <td>
                                <p className="my-2">
                                  {longStringConvertToShort(
                                    element.wallet_address,
                                    30
                                  )}
                                  {/* <a
                                    href={`https://etherscan.io/address/${element.wallet_address}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="mdi mdi-open-in-new ms-2"></i>
                                  </a> */}

                                  <b className="mb-0">
                                    <i
                                      role="button"
                                      onClick={() =>
                                        handleClickCopy(element.wallet_address)
                                      }
                                      className="mdi mdi-content-copy ms-2"
                                    ></i>{" "}
                                  </b>
                                </p>
                              </td>

                              <td>
                                {HRNumbers.toHumanString(
                                  Number(element.amount).toFixed(5)
                                )}{" "}
                                USDC
                                <p className="my-2">
                                  Worth-
                                  {Number(element.usd_value) >= 1000000
                                    ? (
                                        Number(element.usd_value) / 1000000
                                      ).toFixed(5) + "M"
                                    : (
                                        Number(element.usd_value) / 1000
                                      ).toFixed(2) + "K"}
                                </p>
                              </td>

                              <td className="my-5" style={{ width: "150px" }}>
                                <Button
                                  className="btn btn-sm"
                                  style={{
                                    backgroundColor: "blue",
                                    color: "white",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      `https://etherscan.io/address/${element.wallet_address}`
                                    );
                                  }}
                                >
                                  View
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9}>No Records Found</td>
                        </tr>
                      )}
                    </Tbody>
                  </Table>
                  <Row className="mt-2">
                    {/* <Col md={9}></Col> */}
                    {/* <Col md={3}> */}
                    {/* <Pagination setPage={setPage} page={page} data={listingData} /> */}
                    {/* <Pagination
                        {...bootstrap5PaginationPreset}
                        current={currentPage}
                        total={totalCount}
                        onPageChange={(page) => handlePageClick(page)}
                        className="pagination justify-content-end text-dark"
                      />
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="p-0 m-0">Import CSV </h5>
        </Modal.Header>
        <Modal.Body>
          <form className="m-2">
            <div className="row">
              <div className="col-12">
                <Form.Group className="mb-3">
                  <Form.Label> Select File </Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
                </Form.Group>
              </div>
              <div className="col-12 mb-4">
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  id={`default-1`}
                  label={`Replace with the previous data`}
                />
              </div>

              <div className="col-6">
                <button
                  type="button"
                  onClick={() => handleSaveButton()}
                  className="btn btn-primary"
                >
                  {" "}
                  Save{" "}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

USDC.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(USDC);
