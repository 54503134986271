import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import moment from "moment"
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table"
import HRNumbers from "human-readable-numbers"

const GeneralInfoModal = props => {
  const { isOpen, toggle ,data} = props
  console.log(data);
  
  const fullDateFormat = (date) => {
    return (
      <>
        {moment(date).format('MMM DD, YYYY')}
        <br />
        <small>{moment(date).format('h:mm a')}</small>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      size="lg" style={{maxWidth: '600px', width: '100%'}}
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>{data.name} General Info</ModalHeader>
        <ModalBody>
          {/* <p className="mb-2">
            User Name: <span className="text-primary">{data.name}</span>
          </p>
          <p className="mb-4">
            User Email: <span className="text-primary">{data.email}</span>
          </p> */}

          <div className="table-responsive">
                                <Table className="table align-middle table-nowrap">
                                <Thead>
                                    <Tr>
                                    <Th scope="col">Total Supply</Th>
                                    {/* <th scope="col">Fdv</th> */}
                                    <Th scope="col">Market Cap</Th>
                                    <Th scope="col">Holders</Th>
                                    <Th scope="col">Transactions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                
                                    <td>
                                        <div>
                                        <p> {data.totalSupply ? (HRNumbers.toHumanString( data.totalSupply)) : "" }  </p> 
                                        </div>
                                    </td>
                                    
                                
                                    <td>
                                        <div>
                                        <p> {data.mcap ==null ? "-" : (HRNumbers.toHumanString( data.mcap)) }  </p> 
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                        <p className="text-muted">{data.holders}</p>
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                        <p className="text-muted">{data.transactions}</p>
                                        </div>
                                    </td>
                                
                    
                                </Tbody>
                                </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

GeneralInfoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data:PropTypes.object
}

export default GeneralInfoModal
