import React from "react"
import { Redirect } from "react-router-dom"

// // Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Admin Dashboard
import AdminDashboard from "../admin/dashboard/index"
import AdminTokenRejected from "../admin/dashboard/tokenRejected"
import AdminTokenLevel0 from "../admin/dashboard/tokenLevel0"
import AdminTokenLevel1 from "../admin/dashboard/tokenLevel1"
import AdminTokenLevel2 from "../admin/dashboard/tokenLevel2"
import AdminGemTokens from "../admin/dashboard/gemTokens"
import AdminUnderProgress from "../admin/dashboard/progress"
import AdminRejectedToken from "../admin/dashboard/rejected"
import AdminGemsToken from "../admin/dashboard/gems"
import AdminChangePassword from "../admin/account/index"
import AdminGeneralSetting from "../admin/account/general"
import EthPair from "pages/Authentication/HotPairs/EthPair"
import BscPair from "pages/Authentication/HotPairs/BscPair"
import SolanaPair from "pages/Authentication/HotPairs/SolanaPair"
import BasePair from "pages/Authentication/HotPairs/BasePair"
import weth from "pages/Authentication/HotWallets/weth"
import bnb from "pages/Authentication/HotWallets/bnb"
import usdt from "pages/Authentication/HotWallets/usdt"
import usdc from "pages/Authentication/HotWallets/usdc"

const authProtectedRoutes = [
  // Admin Routes
  { path: "/admin/dashboard", component: AdminDashboard },
  { path: "/admin/underprogress", component: AdminUnderProgress },
  { path: "/admin/rejected", component: AdminRejectedToken },
  { path: "/admin/gems", component: AdminGemsToken },
  { path: "/admin/token/rejected", component: AdminTokenRejected },
  { path: "/admin/token/level0", component: AdminTokenLevel0 },
  { path: "/admin/token/level1", component: AdminTokenLevel1 },
  { path: "/admin/token/level2", component: AdminTokenLevel2 },
  { path: "/admin/gem/tokens", component: AdminGemTokens },

  { path: "/eth", component: EthPair },
  { path: "/bsc", component: BscPair },
  { path: "/solana", component: SolanaPair },
  { path: "/base", component: BasePair },

  { path: "/w-eth", component: weth },
  { path: "/bnb", component: bnb },
  { path: "/usdt", component: usdt },
  { path: "/usdc", component: usdc },

  { path: "/admin/change-password", component: AdminChangePassword },
  { path: "/admin/profile", component: AdminChangePassword },
  { path: "/admin/general", component: AdminGeneralSetting },

  { path: "/dashboard", component: AdminDashboard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

]

export { authProtectedRoutes, publicRoutes }
